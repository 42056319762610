import { inject } from '@angular/core'
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router'
import { ModalController } from '@ionic/angular'
import { InactiveServiceCardComponent } from '@modules/modal/components/inactive-service/inactive-service-card.component'
import { AppStore } from '@modules/app/app.store'

export const canActivateChildAuthInactive = async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const appStore = inject(AppStore)
  const modalController = inject(ModalController)
  const urlsOpen: string[] = []
  let modalOpen: boolean = false

  const modalInactiveService = route?.data?.['modalInactiveService']
  if (modalInactiveService === false) {
    return Promise.resolve(true)
  }

  //if url is open, return true
  if (urlsOpen.includes(state.url) && modalInactiveService?.block === false) {
    return Promise.resolve(!modalInactiveService?.block)
  }
  const prevModal = await modalController.getTop()

  if (prevModal?.id === 'inactive_service') {
    modalOpen = true
  }

  if (appStore.user().profile?.hasInactiveService) {
    //verify if has modal open, for no duplicate modal
    if (!modalOpen) {
      urlsOpen.push(state.url)
      const modal = await modalController.create({
        component: InactiveServiceCardComponent,
        cssClass: 'modal-inactive-service',
        backdropDismiss: false,
        id: 'inactive_service',
      })
      await modal.present()
    }
    return !modalInactiveService?.block
  }
  return true
}
