import { Component } from '@angular/core'
import { ModalController } from '@ionic/angular'

@Component({
  selector: 'app-inactive-service-card',
  templateUrl: './inactive-service-card.component.html',
})
export class InactiveServiceCardComponent {
  constructor(private _modalController: ModalController) {}

  onDismiss(): void {
    this._modalController.dismiss({
      dismissed: true,
    })
  }
}
