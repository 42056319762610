import type { IProfile } from '@modules/user/interfaces/profile'
import { UserStatus } from '@modules/user/interfaces/user'
import { EBenefitPlanStatus, IBenefitsPlans } from '@modules/benefit/interfaces/benefit'
import { ETutorialType } from '@modules/guide/tutorial/interfaces/tutorial.interface'
import { IServicesAvailableStatus } from '@modules/user/models/services'

export class Profile {
  accountNumber: string

  accountType: string

  id: string

  benefits_status!: EBenefitPlanStatus

  premium_by_company: boolean

  bankName: string

  cardNumber: string

  clabe: string

  company: string

  montoId: string

  cellphone: string

  corporateEmail: string

  firstName: string

  lastName: string

  paymentFrequency: string

  profilePictureUrl: string

  salary: number

  activeStatus: string

  isActive: boolean

  status: UserStatus

  tutorials: Record<ETutorialType, boolean>

  change_password: boolean | null
  is_first_time_login: boolean | null
  migrated: boolean | null
  hasInactiveService: boolean
  companyId: string
  pennyCheck: boolean
  isCreditActive: boolean
  isMontoShopActive: boolean
  services: IServicesAvailableStatus

  allowPaymentCard: boolean
  hasMFA: boolean
  minMonthsSeniorityRequired: number
  hasRecommendedMonto: boolean
  hasMontoHealth: boolean
  unDocTresBaseUrl: string

  constructor(attrs: IProfile) {
    this.accountNumber = attrs.account_number
    this.accountType = attrs.account_type
    this.id = attrs.id
    this.pennyCheck = attrs.is_penny_checked
    this.bankName = attrs.bank_name
    this.cardNumber = attrs.card_number
    this.clabe = attrs.clabe
    this.change_password = attrs.change_password
    this.premium_by_company = attrs.premium_by_company
    this.is_first_time_login = attrs.is_first_time_login
    this.migrated = attrs.migrated
    this.company = attrs.company
    this.services = attrs.services
    this.montoId = attrs.monto_id
    this.cellphone = attrs.cellphone
    this.corporateEmail = attrs.corporate_email
    this.firstName = attrs.first_name
    this.lastName = attrs.last_name
    this.paymentFrequency = attrs.payment_frequency
    this.profilePictureUrl = attrs.profile_picture_url.url
    this.salary = attrs.salary
    this.activeStatus = attrs.active_status
    this.isActive = attrs.is_active
    this.status = attrs.status
    this.benefits_status = attrs.benefits_status
    this.hasInactiveService = attrs.has_inactive_service
    this.companyId = attrs.company_id
    this.isCreditActive = attrs.is_credit_active
    this.isMontoShopActive = attrs.is_monto_shop_active
    this.tutorials = attrs.tutorials
    this.allowPaymentCard = attrs.allow_payment_card
    this.hasMFA = attrs.has_mfa
    this.minMonthsSeniorityRequired = attrs.min_months_seniority_required
    this.hasRecommendedMonto = attrs.has_recommended_monto
    this.hasMontoHealth = attrs.has_monto_health
    this.unDocTresBaseUrl = attrs.un_doc_tres_base_url
  }
}
