import { Component, inject } from '@angular/core'
import { IConfig } from '@modules/config/interfaces/config'
import { Store } from '@ngrx/store'
import { IUserState } from '@modules/user/interfaces/user'
import { AppStore } from '@modules/app/app.store'
import { PushPipe } from '@ngrx/component'
import { ConfigPipesModule } from '@modules/config/pipes/config-pipes.module'
import { SanitizeHtmlPipe } from '@pipes/sanitize-html.pipe'

@Component({
  selector: 'app-inactive-service-message',
  templateUrl: './inactive-service-message.component.html',
  imports: [PushPipe, ConfigPipesModule, SanitizeHtmlPipe],
  standalone: true,
})
export class InactiveServiceMessageComponent {
  public inactiveServiceCardMessage =
    'El servicio de <span class="font-semibold">Monto</span> no se encuentra disponible por el momento.'
  public config$
  readonly appStore = inject(AppStore)

  constructor(protected _store: Store<{ config: IConfig; user: IUserState }>) {
    this.config$ = this._store.select('config')
  }
}
