<ion-grid fixed="true" class="p-4 pb-6">
  <ion-row>
    <ion-col size="12">
      <div class="flex justify-end">
        <ion-icon
          (click)="onDismiss()"
          tappable=""
          [color]="'dark'"
          size="large"
          class="-mt-1 -mr-1"
          name="close-outline"
        >
        </ion-icon>
      </div>
    </ion-col>
    <ion-col size="12">
      <div class="rounded-full bg-primary w-30 h-30 text-left mx-auto">
        <img class="w-[69px] ml-[21px] mt-[29px]" src="assets/images/application/welcome/img_logo_white.svg" />
      </div>
    </ion-col>
    <ion-col size="12" class="pt-6">
      <div class="text-dark text-center text-md leading-6">
        <app-inactive-service-message></app-inactive-service-message>
      </div>
    </ion-col>
    <ion-col size="12" class="p-4 pb-0 pt-6 text-center">
      <a class="text-primary text-md" tappable="" (click)="onDismiss()">Entendido</a>
    </ion-col>
  </ion-row>
</ion-grid>
